import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const EsGuidesIRuthra: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>introduction to pvp</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_pvp.webp"
            alt="PVP Guide"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to PVP</h1>
          <h2>
            Struggling with PVP? This guide will help you understand the mode
            and the meta unit picks for it!
          </h2>
          <p>
            Last updated: <strong>05/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction to PvP" />
        <p>
          PvP refers to Player vs Player content, Eversoul features two game
          modes featuring PvP content - Arena and Champions Arena.
        </p>
        <StaticImage
          src="../../../images/eversoul/master/game_modes_4.webp"
          alt="Game Modes 4"
        />
        <h5>Arena</h5>
        <p>
          The PvP mode of Eversoul, you select 5 characters to form your attack
          and defense teams and choose the opponent to attack. The fights are
          completely automatic without the option to manual, and as such you can
          completely skip them from the attack screen itself making them go very
          quickly. Since it's fully automatic, positioning and choosing counter
          souls to the enemy is the important part here. But eversoul being the
          generous game it is, allows you to infinitely refresh your opponents
          every 5 seconds, allowing you to get easy matches for maximum score
          gains (supplemented by the fact that many players use weak defense
          teams on purpose to allow others to win easily).
        </p>
        <h5>Champions Arena</h5>
        <p>
          Another PvP mode, similar to Arena, but with 3 teams instead, and with
          addition of treasures which you can set per team. You need to win 2
          out of 3 matches to win these. The difference here is that it is not
          point-based ranking like regular Arena, but a rank swap with the
          winner taking the loser’s rank and sending them down to their old
          rank.
        </p>
        <SectionHeader title="Basic PVP Mechanics & Team Formation" />
        <p>
          As described above, Arena features a simple one-team 5v5 fight, thus
          it is much more simple mechanically compared to Champions Arena, which
          uses multiple teams and a treasure system. Regardless, both of them
          have some common aspects when it comes to drafting teams for them.
        </p>
        <p>
          Arena and Champion Arena being automatic content makes it a
          reactionary content, where you aim at countering opponents defense
          formation in order to increase your chances of winning. Next we will
          discuss some tips to remember while forming your PvP teams.
        </p>
        <p>
          First and foremost, speed is everything, be it offense or defense. The
          vast majority of Arena matches are simply decided by which characters
          go first, as there are a good chunk of meta units that can cripple
          enemy teams in a matter of seconds by going first. Because of this,
          you should ensure your main DPS and CC are going before enemy team’s.
        </p>
        <p>
          It is also important to remember that units will not use their
          Ultimates in Arena. This makes choosing units that rely on their
          Ultimate as the main part of their kit not particularly good choices.
        </p>
        <h5>Defense Teams</h5>
        <p>
          While creating defense teams, the first priority is to focus as much
          on making your team hard to counter by the opponent. While making a
          team that can’t be countered entirely is impossible, there are still
          certain steps you can take to avoid being a victim of positioning
          counters.
        </p>
        <ul>
          <li>
            1-4 Formations or 2-3 Formations are best suited for defense, as it
            allows you to position your Main DPS at 2nd or 3rd Position in 1-4
            or Middle in 2-3 Position. This allows your main DPS to become
            immune to the immediate danger of assassins such as Jacqueline, who
            jumps toward opposite souls on the ends, as well as safeguarding
            yourself from Adrianne’s Pull and similar mechanics.
          </li>
          <li>
            Units with invincibility or invulnerability, or that have cheat
            death mechanics, allow you to counter high DPS teams and avoid
            getting instant losses while allowing your DPS to do their job.
          </li>
          <li>
            Crowd Controllers - especially AoE ones like Honglan or Ayame, which
            can impact Speed and Mana Generation as well, are especially
            valuable in delaying enemies and allowing you to do more.
          </li>
          <li>
            Backline Jumpers are also a good asset to disrupt enemy formations
            and divert their DPS focus while you burst down their other units.
          </li>
          <li>
            Building mixed teams can make it harder to counter your entire team
            at once by using the Faction they’re weak to.
          </li>
          <li>
            In Champion Arena - Set treasures that enhance your team's strong
            points the most or cover their weakness the best.
          </li>
        </ul>
        <h5>Attack Teams</h5>
        <p>
          The priority when drafting Attack Teams is to focus as much as
          possible on countering your opponent’s composition, as it allows you
          to win much more easily. Some other Tips & Suggestions:
        </p>
        <ul>
          <li>
            Don’t Position your Main DPS/CC in the way of enemy assassins and
            backline jumpers.
          </li>
          <li>
            Position your assassins/backline jumpers so they target enemy main
            DPS or CC.
          </li>
          <li>Units with Invulnerabilities are great assets.</li>
          <li>
            Crowd Controllers/Debuffers are invaluable as they can instantly
            cripple multiple units of an enemy team on initiating combat, while
            decreasing their speed/mana regen.
          </li>
          <li>
            Hyper Carries work to great success in this content as they kill
            faster, and the faster you reduce enemy numbers, the more chances of
            you winning.
          </li>
          <li>
            Countering the enemy team’s Faction with whichever Faction they’re
            weak against can help - especially since one of the treasures you
            have to choose is a Faction Idol.
          </li>
          <li>
            In Champion Arena - Set treasures that counter enemy teams most
            effectively or increase your team's DPS.
          </li>
        </ul>
        <SectionHeader title="Champion Arena Treasures" />
        <p>
          Outside of the obvious 3 team roster, the other difference in Champs
          Arena is the choice of Treasures to aid your team. There are three
          groups of Treasures, and you’re only allowed to choose one per team,
          and it has to be one from each Treasure Group (as in, you can’t choose
          a treasure from the same group for 2 different teams). Here’s a quick
          overview of the options, and suggestions for how and when to use them.
        </p>
        <StaticImage
          src="../../../images/eversoul/master/pvp_treasures.webp"
          alt="Champion Arena Treasures"
        />
        <h5>Group 1 - Faction Idols</h5>
        <p>
          These are very basic and don’t really need an explanation. One of your
          teams is going to use one of these, so it’s most likely going to be
          the team that either A) is mostly composed of a single Faction or B)
          doesn’t use the other treasures as effectively as your other teams.
          The treasure will provide either ATK +30% for Human/Beast/Fairy/Undead
          Faction, or, ATK +15% universally.
        </p>
        <h5>Group 2 - Position-Based Treasures</h5>
        <p>
          These are varied but all follow a theme; they will buff/debuff based
          on the unit's positions in their formation. Some are likely more
          useful than others here, but almost all of them are potentially viable
          if you position your team well.
        </p>
        <ul>
          <li>
            <strong>Vanguard’s Spear Flag</strong> - Great for when you want to
            hide a DPS in the front row, usually when you’re trying to protect
            them from enemy assassins. Also helps your Supports increase their
            heals.
          </li>
          <li>
            <strong>Spectacular Smoke Signal</strong> - Same idea, but for more
            traditional formations where your DPS are in the back.
          </li>
          <li>
            <strong>Artisan’s Hammer</strong> - Great if your enemy has a
            front-row-heavy formation, or a particularly tanky frontline that
            you’d like to rid yourself of quickly.
          </li>
          <li>
            <strong>Three-Part Puzzle Piece</strong> - Proximity-based buff, for
            when your team is clumped together. Not as reliable as the other
            options, as the buff can be lost if your enemy displaces your units,
            or if your units end up spread out to chase the enemy.
          </li>
          <li>
            <strong>Loner’s Favourite Doll</strong> - Same as above, but even
            less likely to work in your favour, as having your units spread out
            opens them up to being pulled or picked off. Use with caution.
          </li>
        </ul>
        <h5>Group 3 - Random Shit, GO!</h5>
        <p>
          This group has probably the strongest options, which is unfortunate
          since you’re only allowed to choose one.{' '}
        </p>
        <ul>
          <li>
            <strong>Corrupted Mana Crystal</strong> - Very strong, for obvious
            reasons. As has been mentioned previously, skilling first and more
            often wins fights, so of course slowing your opponent’s skills down
            is a great boon.
          </li>
          <li>
            <strong>Mithril Eisen</strong> - A great counter to those obnoxious
            teams that aim to CC you right out of the gate. Most battles end in
            60 seconds or less anyway, so it’s basically a full battle’s worth
            of CC immunity!
          </li>
          <li>
            <strong>Quack Doc’s Torn Prescription</strong> - A fun way to tell
            the enemy Supports, Sustain-Tanks, and Lifestealing DPS to screw
            off.
          </li>
          <li>
            <strong>Cemetery Corpse-Flower</strong> - Obviously only good for
            DoT teams, but with how much damage Claudia and Hazel can dish out
            via Shock, a pretty good, though more niche option.
          </li>
          <li>
            <strong>Growing Wooden Club</strong> - Probably the weakest one
            here, since battles are often decided quickly. If you feel like
            trying a slow burn team that’s full of sustain and tanks.
          </li>
        </ul>
        <SectionHeader title="Characters that excel in PVP" />
        <p>
          Eversoul has a lot of souls, and while PvP is much more forgiving here
          than other games we support, there are still certain souls that excel
          in this mode and make your time much easier - be it on defense teams
          or offense teams.
        </p>
        <ul>
          <li>
            <strong>Adrianne</strong> - She has great disrupting potential when
            positioned well, Adrianne can pull the enemy’s main DPS or CC unit,
            allowing your team to make quick work of them. While she is easier
            to use on offense due to being able to position her, she can still
            work on defense if your opponent is caught unaware.
          </li>
          <li>
            <strong>Aki</strong> - Excelling as DPS in most of the content, PvP
            is no exception, as a properly boosted Aki can make quick work of
            enemy teams by jumping around the battlefield executing souls and
            spamming skills. However, to do the same she needs to be properly
            invested in as a Hyper Carry.
          </li>
          <li>
            <strong>Ayame</strong> - Originally a mediocre soul, Ayame was
            buffed and is now a great option for CC and mana manipulation. Her
            new skill set allows her to bind multiple enemy souls at the start
            of a match, making her an excellent counter to menaces like Aki
            which rely on moving around the field to maximize their usage. Not
            only that, the plethora of debuffs she lands on the enemy team along
            with Mana Manipulation and her overall high damage, Ayame can help
            you win matches with much more ease.
          </li>
          <li>
            <strong>Beleth</strong> - One of the best Supports in the game,
            Beleth offers quite a bit of survivability through HP boosts,
            shields, and massive amounts of heals. She also provides your
            highest ATK unit (so, your carry) with crit rate and crit dmg buffs!
          </li>
          <li>
            <strong>Catherine (Radiant)</strong> - Without access to use her
            Ultimate, Radiant Cath can’t be used as a carry in Arena, however,
            her Support capabilities are amazing both offensively and
            defensively. She can boost the ATK of your DPS and the DEF of your
            Tank and Supports, while providing beefy shields to keep your team
            alive.
          </li>
          <li>
            <strong>Chloe</strong> - An old choice for the role of Tank, Chloe
            still manages her spot in the unrelenting times of Daphne meta owing
            to her high level of survivability and defense skills.
          </li>
          <li>
            <strong>Claudia</strong> - The Queen of AoE fits just as well in
            Arena as she does in regular 5v5 PvE situations, assuming you can
            keep her alive. She does require strategic positioning to protect
            her from assassins in order to throw out her insane AoE damage. She
            also lacks her Ultimate, unfortunately, but is still able to provide
            huge damage.
          </li>
          <li>
            <strong>Daphne</strong> - Once upon a time, there were various tanks
            that were used in PvP comps depending on the team, until Daphne
            happened. She immediately took the core spot of Tank in team by
            storm, just like she did in PvE. Damage Sharing, Invulnerability and
            Damage Reflect makes her an unstoppable force in the mode.
          </li>
          <li>
            <strong>Eve</strong> - Eve quickly made her way to the PvP meta on
            her launch, providing an unmatched support to hyper carries like
            Lizelotte and Aki, allowing them to function on steroids and
            decimate enemy teams in seconds, as well as survive longer thanks to
            her damage sharing abilities.
          </li>
          <li>
            <strong>Honglan</strong> - Still one of the most used units in the
            mode, Honglan is an excellent crowd control with her Speed, Evasion
            and Mana manipulation. She can alter the fate of a match quickly by
            gimping the enemy team’s skills and allowing your team to skill
            first. She does, however, require safe positioning as she is rather
            squishy in today’s meta.
          </li>
          <li>
            <strong>Jacqueline</strong> - The OG assassin, Jacqueline has been
            top pick for PvP since launch of game. With her backline disruption
            ability and overall high DPS, left unchecked she can make quick work
            of backline DPS and CC units. At worst, she serves as a good
            distraction, disrupting enemy formation and moving their focus to
            her, allowing her teammates to do more.
          </li>
          <li>
            <strong>Linzy</strong> - Similar to Aki, Linzy post-buffs turned
            into a menace, dancing around the field annoying the enemy with her
            Evasion, but she shares the same restrictions of needing significant
            investment to one-shot enemies.
          </li>
          <li>
            <strong>Lizelotte</strong> - Originally a mediocre choice for PvP,
            Lizelotte rose to prominence quickly with the addition of Eve to the
            roster. It allowed her to survive longer thanks to Eve’s damage
            sharing and turn into absolute menace DPS wise by doubling Eve’s
            attack buffs. Lizelotte can immediately one-shot enemy souls,
            including Tanks and even Daphne, bypassing her invulnerability
            allowing her to single-handedly win the match. Similar to other ST
            DPS like Aki and Linzy, she also needs significant investment to
            one-shot characters, though this is mitigated to an extent with her
            innate ability to double the buff effects on her.
          </li>
          <li>
            <strong>Naiah</strong> - The bane of every Jacqueline’s existence,
            Naiah completely shuts down Jacqueline’s strength; that is
            disruption of backline and killing a DPS, as Naiah puts her to sleep
            as soon as Jacqueline moves to back thanks to her passive. In
            addition to this, Naiah deals great AoE DPS and throws out loads of
            crowd control in the form of sleep.
          </li>
          <li>
            <strong>Otoha</strong> - Surprisingly effective in Arena, Otoha is
            able to shut down one of the biggest hindrances you’ll encounter;
            Daphne. When Daphne tanks her team's worth of damage and triggers
            her Invuln, Otoha can save you the hassle of having to deal with her
            any further with her execute skill that triggers on enemies with
            less than 3% HP.{' '}
          </li>
          <li>
            <strong>Prim</strong> - Another solid Support option, Prim being on
            the team has a big impact against assassin type enemies by reducing
            the chance your team will be critically hit by 25%. She became a
            more viable option since her buff, which allows her to build up mana
            more quickly than before.
          </li>
          <li>
            <strong>Vivienne</strong> - Speed, speed and speed, Vivienne
            increases your team’s speed and has very fast skill cycling,
            allowing her to skill often and whittle down the enemy team.
          </li>
          <li>
            <strong>Yuria</strong> - The undisputed Queen of Support is no
            slouch in PvP mode as well, with her insane healing and support
            abilities allowing her to sneak into any team with ease and make
            significant contributions to it.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesIRuthra;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to PVP | Eversoul | Prydwen Institute"
    description="struggling with PVP? This guide will help you understand the mode and the meta unit picks for it!"
    game="eversoul"
  />
);
